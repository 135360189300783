import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromCents',
})
export class FromCentsPipe implements PipeTransform {
  transform(amount: number): any {
    return amount / 100;
  }
}
