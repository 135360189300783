import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionService } from '@x/ecommerce/domain-client';
import {
  ChannelAutocompleteDataSource,
  ProductVariantAutocompleteDatasource,
} from '@x/ecommerce/domain-data';
import { CreateSubscriptionInput } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface SubscriptionDialogData {
  subscriptionId?: number;
}

export interface SubscriptionDialogResult {
  data?: { id: number };
}

@Component({
  selector: 'x-subscription-create-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss'],
  providers: [ProductVariantAutocompleteDatasource, ChannelAutocompleteDataSource],
})
export class SubscriptionDialogComponent implements OnInit, OnDestroy {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  subscriptionId: number;
  verb: string = 'Create';

  subscriptionForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public dialog: MatDialogRef<SubscriptionDialogComponent>,
    private subscriptionService: SubscriptionService,
    public channelDataSource: ChannelAutocompleteDataSource,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionDialogData,
  ) {}

  ngOnInit(): void {
    if (this.data?.subscriptionId) {
      this.subscriptionService
        .fetchItem(this.data?.subscriptionId)
        .pipe(
          tap((option) => {
            this.subscriptionId = option.id;
            this.subscriptionForm.get('name')?.setValue(option.name);
          }),
        )
        .subscribe();
      this.verb = 'Update';
    }
  }

  submit() {
    this.subscriptionForm.updateValueAndValidity();

    if (this.subscriptionForm.invalid) return;

    const form = this.subscriptionForm.value;

    const data: CreateSubscriptionInput = {
      name: form.name,
    };

    this.subscriptionService
      .create(data)
      .pipe(
        takeUntil(this._destroy$),
        tap((data) => this.dialog.close({ data })),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
