<div class="pill-icon">
  <fa-icon icon="id-card"></fa-icon>
</div>
<div class="pill-body">
  <div class="pill-label">{{ member.membership?.name }}</div>
  <div class="pill-line">
    {{ member.channel?.code | uppercase }} |

    <span [ngSwitch]="getState()">
      <span *ngSwitchCase="'ACTIVE-ACTIVE'">Active</span>
      <span *ngSwitchCase="'ACTIVE-PENDING'">
        Starts {{ member.startDate | datetime: 'shortDate' }}
      </span>
      <span *ngSwitchCase="'ACTIVE-EXPIRED'">
        Expired {{ member.expiryDate | datetime: 'shortDate' }}
      </span>
      <span *ngSwitchDefault>Inactive</span>
    </span>
  </div>
</div>
