<div class="menu-item-node" *ngIf="pinnedNodes.length">
  <div
    class="menu-item parent"
    [class.expanded]="isPinnedExpanded"
    matRipple
    (click)="togglePinned()"
  >
    <fa-icon class="item-icon" [icon]="['far', 'thumbtack']" size="lg"></fa-icon>
    <span class="item-title">Pinned</span>
    <fa-icon
      class="item-toggle"
      [icon]="isPinnedExpanded ? 'chevron-down' : 'chevron-right'"
    ></fa-icon>
  </div>
  <div class="menu-item-children" [class.hidden]="!isPinnedExpanded">
    <a
      *ngFor="let node of pinnedNodes; trackBy: trackMenuItem"
      class="menu-item child"
      matRipple
      [routerLink]="node.route"
      [class.item-active]="activeControl.expansionModel.isSelected(node.id)"
    >
      <fa-icon class="item-icon" *ngIf="node.icon" [icon]="node.icon"></fa-icon>
      <span class="item-title">{{ node.title }}</span>
    </a>
  </div>
</div>
<cdk-tree [dataSource]="nodes" [treeControl]="expandedControl" [trackBy]="trackById">
  <!-- This is the tree node template for expandable nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="menu-item-node">
    <div
      class="menu-item parent"
      matRipple
      cdkTreeNodeToggle
      [class.expanded]="expandedControl.isExpanded(node)"
      [class.item-active]="activeControl.isExpanded(node)"
    >
      <fa-icon class="item-icon" *ngIf="node.icon" [icon]="node.icon" size="lg"></fa-icon>
      <span class="item-title">{{ node.title }}</span>
      <fa-icon
        class="item-toggle"
        [icon]="expandedControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"
      ></fa-icon>
    </div>
    <div class="menu-item-children" [class.hidden]="!expandedControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>

  <!-- This is the tree node template for leaf nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="menu-item-node">
    <a
      class="menu-item child"
      matRipple
      [routerLink]="node.route"
      [class.item-active]="activeControl.isExpanded(node)"
    >
      <fa-icon class="item-icon" *ngIf="node.icon" [icon]="node.icon"></fa-icon>
      <span class="item-title">{{ node.title }}</span>
      <fa-icon
        class="item-star"
        icon="thumbtack"
        [rotate]="pinnedControl.isSelected(node.id) ? 90 : undefined"
        (click)="togglePin(node, $event)"
      ></fa-icon>
    </a>
  </cdk-nested-tree-node>
</cdk-tree>
