import { Component, Input } from '@angular/core';
import { ChannelObject } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-channel-pill',
  templateUrl: 'channel-pill.component.html',
  styleUrls: ['channel-pill.component.scss'],
  host: {
    class: 'x-channel-pill x-pill-base',
  },
})
export class ChannelPillComponent {
  @Input()
  channel: Partial<ChannelObject>;
}
