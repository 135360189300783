<span
  class="address-label mat-body-2"
  cdkOverlayOrigin
  (click)="open()"
  #trigger="cdkOverlayOrigin"
  tabindex="0"
>
  <ng-container *ngIf="shouldLabelFloat">
    {{ formattedValue ?? _placeholder }}
  </ng-container>
  &nbsp;
</span>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayDisableClose]="false"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayGrowAfterOpen]="false"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'x-address-overlay-backdrop'"
  (backdropClick)="close()"
  (attach)="onAttachOverlay()"
>
  <x-address-form
    class="x-address-input-overlay"
    [value]="value"
    (changed)="changed($event)"
  ></x-address-form>
</ng-template>
