<h2 mat-dialog-title>{{ verb }} Membership Benefit</h2>
<form
  mat-dialog-content
  [formGroup]="subscriptionMembershipBenefitForm"
  id="subscription_membership_benefit_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <!-- <x-product-variant-autocomplete formControlName="membership"></x-product-variant-autocomplete> -->
      <x-member-membership-filter-field
        formControlName="membershipId"
        [verb]="'Select'"
        [multiple]="false"
      ></x-member-membership-filter-field>
      <mat-form-field appearance="fill">
        <mat-label>
          <fa-icon icon="clock"></fa-icon>
          Duration
        </mat-label>
        <x-duration-input-control
          formControlName="duration"
          [format]="['mth', 'wk', 'd', 'hr']"
        ></x-duration-input-control>
        <mat-hint>Duration, empty indicates no-expiry</mat-hint>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>

  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="dialog.close()">Cancel</button>
    <button mat-button type="submit" color="primary" form="subscription_membership_benefit_form">
      {{ verb }}
    </button>
  </div>
</form>
