import { Component, HostBinding, Input } from '@angular/core';
import { ISubscriberTagItem } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-subscriber-tag-pill',
  templateUrl: 'subscriber-tag-pill.component.html',
  styleUrls: ['subscriber-tag-pill.component.scss'],
  host: {
    class: 'x-subscriber-tag-pill',
  },
})
export class SubscriberTagPillComponent {
  @Input()
  tag: Partial<ISubscriberTagItem>;

  @HostBinding('class')
  get colorClass() {
    return this.tag.color ? `color-${this.tag.color}` : 'color-default';
  }
}
