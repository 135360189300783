<div class="pill-icon">
  <fa-icon icon="credit-card"></fa-icon>
</div>
<div
  class="pill-body"
  *ngIf="userPaymentMethod as method; else unassigned"
  [matTooltip]="userPaymentMethod.errorDescription ?? ''"
>
  <div class="pill-label" *ngIf="method.description">
    <span>{{ method.description }}</span>
  </div>
  <div class="pill-line">
    <span>
      {{
        [
          method.state,
          method.method.name,
          method.expired ? '&#10007; Expired' : null,
          method.archivedAt ? '&#10007; Deleted' : null
        ] | join : ' | '
      }}
    </span>
  </div>
</div>
<ng-template #unassigned>
  <div class="pill-body">Unassigned</div>
</ng-template>
