import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IAddressInput } from '@x/ecommerce/domain-client';
import {
  CountryAutocompleteDatasource,
  CountryCodeTransformer,
  GeocodeAutocompleteDatasource,
  ProvinceAutocompleteDatasource,
  ProvinceCodeTransformer,
} from '@x/ecommerce/domain-data';
import { GeocodeService, IExpandedAddress } from '@x/geocode/client';
import { AddressType, PointInput } from '@x/schemas/ecommerce';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-address-form',
  templateUrl: 'address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: AddressFormComponent },
    GeocodeAutocompleteDatasource,
    CountryAutocompleteDatasource,
    CountryCodeTransformer,
    ProvinceAutocompleteDatasource,
    ProvinceCodeTransformer,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent
  implements
    ControlValueAccessor,
    OnInit,
    AfterViewInit,
    OnDestroy,
    MatFormFieldControl<IAddressInput>
{
  static nextId = 0;

  @HostBinding()
  id = `x-address-form-${AddressFormComponent.nextId++}`;
  controlType = 'x-address-form';

  @Input()
  set value(value: IAddressInput | null) {
    this.writeValue(value);
  }
  get value() {
    return this._value;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  _placeholder: string;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.addressGroup.disable() : this.addressGroup.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  get empty() {
    return !this.value;
  }
  get errorState(): boolean {
    return (
      (this.addressGroup.invalid || !!this.ngControl?.control?.invalid) && this.addressGroup.touched
    );
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @ViewChild('mapControl', { static: false })
  mapControl: GoogleMap;

  mapOptions: google.maps.MapOptions;

  @Input() defaultZoom: number = 10;
  @Input() defaultCoordinates: PointInput = {
    lat: -33.822505,
    lng: 18.929985,
  };

  focused = false;
  stateChanges = new Subject<void>();

  _value: IAddressInput | null;

  addressGroup = new UntypedFormGroup({
    type: new UntypedFormControl(AddressType.Physical),
    alias: new UntypedFormControl(),
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    email: new UntypedFormControl(),
    phoneNumber: new UntypedFormControl(),
    street: new UntypedFormControl(),
    complex: new UntypedFormControl(),
    suburb: new UntypedFormControl(),
    city: new UntypedFormControl(),
    businessName: new UntypedFormControl(),
    postalCode: new UntypedFormControl(),
    province: new UntypedFormControl(),
    country: new UntypedFormControl(null, Validators.required),
    coordinates: new UntypedFormGroup({
      lat: new UntypedFormControl(),
      lng: new UntypedFormControl(),
    }),
    instructions: new UntypedFormControl(),
  });

  addressSearchControl = new FormControl<IExpandedAddress | null>(null);
  addressMarker$ = new BehaviorSubject<PointInput | null>(null);

  showMap = false;

  private _destroy$ = new Subject<void>();
  private _onTouched: any = () => {};
  private _onChange: any = () => {};

  @Output() changed = new EventEmitter();

  constructor(
    public geocodeAutocompleteDataSource: GeocodeAutocompleteDatasource,
    public countryAutocompleteDataSource: CountryAutocompleteDatasource,
    public countryCodeTransformer: CountryCodeTransformer,
    public provinceAutocompleteDataSource: ProvinceAutocompleteDatasource,
    public provinceCodeTransformer: ProvinceCodeTransformer,
    private geocodeService: GeocodeService,
    @Optional() @Self() public ngControl: NgControl,
    private changeRef: ChangeDetectorRef,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.addressSearchControl.valueChanges.subscribe((result) => {
      if (result) {
        const coordinates: PointInput | undefined =
          result.lat && result.lng ? { lat: result.lat, lng: result.lng } : undefined;

        this.addressGroup.patchValue({
          street: result.street,
          complex: result.complex,
          businessName: result.establishment,
          suburb: result.suburb,
          city: result.city,
          postalCode: result.postcode,
          province: result.provinceCode,
          country: result.countryCode,
          coordinates,
        });

        if (coordinates) {
          this.addressMarker$.next(coordinates);
          this.centerMapOnAddress(coordinates);
        }
      }
    });

    this.addressGroup.valueChanges
      .pipe(
        tap((v) => {
          this._value = this.addressGroup.value;

          if (this._value) {
            const { coordinates } = this._value;

            if (!Number(coordinates?.lat) || !Number(coordinates?.lng))
              this._value.coordinates = null;
          }

          this._onChange(this._value);
          this._onTouched();
          this.changed.emit(this._value);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.defaultZoom && this.defaultCoordinates) {
      this.mapOptions = {
        zoom: this.defaultZoom,
        mapTypeId: 'roadmap',
        backgroundColor: 'transparent',
        disableDefaultUI: true,
        zoomControl: false,
        scaleControl: false,
        mapTypeControl: false,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        center: this.defaultCoordinates,
      };
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(address: any): void {
    this._value = address;

    if (typeof address === 'object' && address) {
      this.addressGroup.patchValue(
        {
          type: AddressType.Physical,
          alias: address.alias ?? null,
          firstName: address.firstName ?? null,
          lastName: address.lastName ?? null,
          email: address.email ?? null,
          phoneNumber: address.phoneNumber ?? null,
          street: address.street ?? null,
          complex: address.complex ?? null,
          suburb: address.suburb ?? null,
          city: address.city ?? null,
          businessName: address.businessName ?? null,
          postalCode: address.postalCode ?? null,
          province: address.province ?? null,
          country: address.country ?? null,
          instructions: address.instructions ?? null,
          coordinates:
            address.coordinates?.lat && address.coordinates?.lng
              ? {
                  lat: address.coordinates.lat,
                  lng: address.coordinates.lng,
                }
              : { lat: null, lng: null },
        },
        { emitEvent: false },
      );
      this.updateMapView();
    } else {
      this.addressGroup.setValue(
        {
          type: AddressType.Physical,
          alias: null,
          firstName: null,
          lastName: null,
          email: null,
          phoneNumber: null,
          street: null,
          complex: null,
          suburb: null,
          city: null,
          businessName: null,
          postalCode: null,
          province: null,
          country: null,
          instructions: null,
          coordinates: { lat: null, lng: null },
        },
        { emitEvent: false },
      );

      this.updateMapView();
    }

    this.stateChanges.next();
    this.changeRef.markForCheck();
  }

  centerMapOnAddress(coordinates: PointInput) {
    if (!this.mapControl) return;

    const { lat, lng } = coordinates;
    this.mapControl.panTo({ lat, lng });
  }

  onMapClick(e: google.maps.MapMouseEvent) {
    const coordinates: PointInput = {
      lat: e.latLng?.lat() ?? 0,
      lng: e.latLng?.lng() ?? 0,
    };
    if (this.addressMarker$.value === null) {
      this.addressMarker$.next(coordinates);
      this.centerMapOnAddress(coordinates);
      this.addressGroup.patchValue({
        coordinates,
      });
    }
  }

  markerDragEnd(e: google.maps.MapMouseEvent) {
    const { latLng } = e;
    if (!latLng) return;

    const coordinates: PointInput = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    this.addressGroup.patchValue({
      coordinates,
    });
  }

  toggleShowMap() {
    this.showMap = !this.showMap;
    if (this.showMap) {
      this.updateMapView();
    }
  }

  updateMapView() {
    const coordinates: PointInput | null = this.addressGroup.value.coordinates;
    if (coordinates && coordinates.lat !== null && coordinates.lng !== null) {
      this.addressMarker$.next(coordinates);
      this.centerMapOnAddress(coordinates);
    }
  }

  clear() {
    this.addressGroup.reset(null);
    this.addressGroup.markAsUntouched();
  }

  onContainerClick(event: MouseEvent) {}

  setDescribedByIds(ids: string[]) {}

  trackByValue = (i: number, o: any) => o.value;
}
