import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DeepPartial } from '@x/common/utils';
import { FulfilmentObject } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-fulfilment-pill',
  templateUrl: 'fulfilment-pill.component.html',
  styleUrls: ['fulfilment-pill.component.scss'],
  host: {
    class: 'x-fulfilment-pill x-pill-base',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FulfilmentPillComponent {
  @Input()
  fulfilment: DeepPartial<FulfilmentObject>;

  @HostBinding('class')
  get stateClass() {
    return this.fulfilment.state ? `state-${this.fulfilment.state}` : 'state-default';
  }
}
