import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriberService } from '@x/ecommerce/domain-client';
import {
  AddressItemCollectionProvider,
  ChannelLocaleItemCollectionProvider,
  SubscriptionVariantItemCollectionProvider,
  UserItemCollectionProvider,
  UserPaymentMethodItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { SubscriberState } from '@x/schemas/ecommerce';

export interface SubscriptionFormDialogData {
  id?: number | null;
  label?: string;
  userId?: number | null;
  subscriptionVariantId?: number | null;
  locale?: string | null;
  userPaymentMethodId?: number | null;
  shippingAddressId?: number | null;
  state?: SubscriberState | null;
  startAt?: Date | null;
  endAt?: Date | null;
}

export interface SubscriptionFormDialogResult {
  subscriberId?: number | null;
  userId: number;
  subscriptionVariantId: number;
  locale: string;
  userPaymentMethodId?: number | null;
  shippingAddressId?: number | null;
  state?: SubscriberState | null;
  startAt?: Date | null;
  endAt?: Date | null;
}

@Component({
  selector: 'x-subscriber-form-dialog',
  templateUrl: './subscriber-form-dialog.component.html',
  styleUrls: ['./subscriber-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriberFormDialogComponent implements OnInit {
  readonly Providers = {
    UserItemCollectionProvider,
    AddressItemCollectionProvider,
    UserPaymentMethodItemCollectionProvider,
    SubscriptionVariantItemCollectionProvider,
    ChannelLocaleItemCollectionProvider,
  };

  verb: string;

  @ViewChild(MatDateRangePicker)
  picker: MatDateRangePicker<any>;

  formGroup = new UntypedFormGroup({
    userId: new UntypedFormControl(this.data.userId ?? null, [Validators.required]),
    subscriptionVariantId: new UntypedFormControl(this.data.subscriptionVariantId ?? null, [
      Validators.required,
    ]),
    locale: new UntypedFormControl(this.data.locale ?? null, [Validators.required]),
    userPaymentMethodId: new UntypedFormControl(this.data.userPaymentMethodId ?? null),
    shippingAddressId: new UntypedFormControl(this.data.shippingAddressId ?? null),
    state: new UntypedFormControl(this.data.state ?? SubscriberState.Active),
    startAt: new UntypedFormControl(this.data.startAt ?? null),
    endAt: new UntypedFormControl(this.data.endAt ?? null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SubscriptionFormDialogData,
    private dialogRef: MatDialogRef<SubscriberFormDialogComponent, SubscriptionFormDialogResult>,
    private subscriberService: SubscriberService,
  ) {
    this.verb = data.label ?? 'Create';
  }

  ngOnInit(): void {
    if (this.data.id) {
      this.subscriberService.fetchDetail(this.data.id).subscribe((sub) => {
        this.formGroup.reset({
          userId: sub.user.id,
          subscriptionVariantId: sub.variant.id,
          locale: sub.locale,
          userPaymentMethodId: sub.userPaymentMethod?.id ?? null,
          shippingAddressId: sub.shippingAddress?.id ?? null,
          state: sub.state,
          startAt: sub.startAt,
          endAt: sub.endAt,
        });
      });
      this.formGroup.get('userId')?.disable();
      this.formGroup.get('subscriptionVariantId')?.disable();
      this.formGroup.get('locale')?.disable();
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    this.dialogRef.close({ ...this.formGroup.value, id: this.data.id });
  }

  clear() {
    this.formGroup.patchValue({
      startAt: null,
      endAt: null,
    });
    this.picker.close();
  }
}
