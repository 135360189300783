import { Component, HostBinding, Input } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { IBulkOrderEntryItemObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-bulk-order-entry-pill',
  templateUrl: 'bulk-order-entry-pill.component.html',
  styleUrls: ['bulk-order-entry-pill.component.scss'],
  host: {
    class: 'x-bulk-order-entry-pill x-pill-base',
  },
})
export class BulkOrderEntryPillComponent {
  @Input()
  entry: Partial<IBulkOrderEntryItemObject>;

  @HostBinding('class.in-sync')
  get inSync(): boolean {
    return this.entry.orderTemplateVersion === this.entry.bulkOrder?.template.version;
  }

  @HostBinding('class.error')
  get hasError(): boolean {
    return !!this.entry.errorDescription;
  }

  get icon(): IconName {
    if (this.hasError) {
      return 'triangle-exclamation';
    }

    return this.inSync ? 'rotate' : 'rotate-exclamation';
  }

  get status(): string {
    if (this.entry.errorDescription) {
      return 'Entry processing error occurred';
    }
    return this.inSync ? 'Synced with template' : 'Not in sync';
  }

  get tooltip(): string {
    if (this.entry.errorDescription) {
      return this.entry.errorDescription;
    }
    return this.inSync ? 'Synced with template' : 'Not in sync';
  }
}
