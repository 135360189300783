import { Component, Input } from '@angular/core';
import { IUserDetailObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-user-pill',
  templateUrl: 'user-pill.component.html',
  styleUrls: ['user-pill.component.scss'],
  host: {
    class: 'x-user-pill x-pill-base',
  },
})
export class UserPillComponent {
  @Input()
  user: Partial<IUserDetailObject>;
}
