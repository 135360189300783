import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAddressInput } from '@x/ecommerce/domain-client';

export interface IAddressInputDialogData {
  title: string;
  placeholder?: string;
  okLabel: string;
  cancelLabel: string;
  value?: IAddressInput | null;
}

export interface IAddressInputDialogOptions {
  title?: string;
  placeholder?: string;
  okLabel?: string;
  cancelLabel?: string;
  value?: IAddressInput | null;
}

export interface IAddressInputDialogResult {
  value: IAddressInput;
}

@Component({
  selector: 'x-address-input-dialog',
  templateUrl: 'address-input-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressInputDialogComponent {
  control = new UntypedFormControl(this.data.value ?? null);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IAddressInputDialogData,
  ) {}
}
