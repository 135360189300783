import { InjectionToken } from '@angular/core';

export const MONEY_FORMAT_CONFIG = new InjectionToken<MoneyFormatConfig>(
  'Default MoneyFormatConfig',
);

export interface MoneyFormatConfig {
  /**
   * Decimal representation options, specified by a string
   * in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>
   */
  digitsInfo: string;

  symbolDisplay: 'wide' | 'narrow';
}

export class MoneyModuleConfig {
  format: MoneyFormatConfig;
}
