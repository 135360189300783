import { Component, HostBinding, Input } from '@angular/core';
import { IOrderDetailObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-order-pill',
  templateUrl: 'order-pill.component.html',
  styleUrls: ['order-pill.component.scss'],
  host: {
    class: 'x-order-pill x-pill-base',
  },
})
export class OrderPillComponent {
  @Input()
  order: Partial<IOrderDetailObject>;

  @HostBinding('class')
  get stateClass() {
    return this.order.state ? `state-${this.order.state}` : 'state-default';
  }

  get displayValue(): string {
    return this.order.number ? '#' + this.order.number : 'ID' + this.order.id;
  }
}
