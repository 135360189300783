import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AddressInputDialogComponent,
  IAddressInputDialogData,
  IAddressInputDialogOptions,
  IAddressInputDialogResult,
} from '../components/address-input-dialog/address-input-dialog.component';

@Injectable()
export class AddressDialogService {
  constructor(private matDialog: MatDialog) {}

  openAddressInputDialog(options: IAddressInputDialogOptions) {
    const data: IAddressInputDialogData = {
      title: 'Select Address',
      placeholder: 'Address...',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      ...options,
    };

    return this.matDialog.open<
      AddressInputDialogComponent,
      IAddressInputDialogData,
      IAddressInputDialogResult
    >(AddressInputDialogComponent, { data, width: '660px' });
  }
}
