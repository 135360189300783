<div class="pill-icon">
  @switch (notification.status) {
    @case ('FAILED') {
      <fa-icon [icon]="'circle-exclamation'"></fa-icon>
    }
    @case ('SENT') {
      <fa-icon [icon]="'circle-check'"></fa-icon>
    }
    @default {
      <fa-icon [icon]="'circle'"></fa-icon>
    }
  }
</div>
<div class="pill-body">
  <div class="pill-label" *ngIf="notification.notificationName">
    <span>{{ notification.notificationName | l }}</span>
    &nbsp;
  </div>
  <div>
    <span *ngIf="notification.status">
      {{ notification.status | l }}
    </span>
    &nbsp;|&nbsp;
    {{ notification.sentAt | datetime: 'short' }}
  </div>
</div>
