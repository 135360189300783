import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { IAddressObject, UserService } from '@x/ecommerce/domain-client';
import { AddressFilterInput } from '@x/schemas/ecommerce';
import { firstValueFrom } from 'rxjs';
import { AddressDialogService } from '../../services/address-dialog.service';

@Component({
  selector: 'x-address-table',
  templateUrl: './address-table.component.html',
  styleUrls: ['./address-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-address-table',
  },
})
export class AddressTableComponent {
  @Input() view: DataTableView<IAddressObject, AddressFilterInput, any, number>;

  @Input()
  userId: number;

  constructor(
    private addressDialogService: AddressDialogService,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private prompts: PromptDialogService,
  ) {}

  async editAddress(address: IAddressObject) {
    if (!this.userId) {
      this.snackbar.open(`User required to update the user's address`, 'OK', { duration: 5000 });
      return;
    }

    const result = await firstValueFrom(
      this.addressDialogService
        .openAddressInputDialog({
          title: `Edit User's Address`,
          okLabel: 'Update',
          value: address,
        })
        .afterClosed(),
    );

    if (!result) return;

    this.view
      .mutateRow(address.id, (addressId) =>
        this.userService.updateAddress({ addressId, address: result.value, userId: this.userId }),
      )
      .subscribe();
  }

  async archiveAddress(addressId: number) {
    if (!this.userId) {
      this.snackbar.open(`User required to archive the user's address`, 'OK', { duration: 5000 });
      return;
    }

    const result = await firstValueFrom(
      this.prompts.confirm({
        title: `Archive User's Address`,
        message: 'Are you sure you want to archive this address?',
      }),
    );

    if (!result) return;

    this.view
      .mutateRow(addressId, (addressId) =>
        this.userService.archiveAddress({ addressId, userId: this.userId }),
      )
      .subscribe();
  }
}
