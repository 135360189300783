import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { LocaleItem } from '@x/common/locale';
import { ChannelContext } from '@x/ecommerce/domain-data';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.scss'],
})
export class LocaleSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private _onChange: any = () => {};
  private _onTouched: any = () => {};
  private _destroy$ = new Subject<void>();

  @Input() label = 'Select locale';

  @Input() multiple: boolean = false;

  @Input()
  set byChannelId(channelId: number) {
    if (channelId) {
      this.locales$ = this.channelContext.getLocalesInChannel(channelId);
    }
  }

  locales$: Observable<LocaleItem[]> = this.channelContext.getLocaleOptionsInAllChannels();

  control = new UntypedFormControl(null);

  constructor(
    private channelContext: ChannelContext,
    @Optional()
    ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  writeValue(locale: LocaleItem): void {
    if (!locale) return;
    this.control.patchValue(locale, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((val) => {
          if (val) {
            this._onChange(val);
            this._onTouched();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
