import { NgModule } from '@angular/core';
import { MarkdownParser } from './mardown-parser';
import { MarkdownViewComponent } from './markdown-view/markdown-view.component';

@NgModule({
  declarations: [MarkdownViewComponent],
  exports: [MarkdownViewComponent],
  providers: [MarkdownParser],
})
export class MarkdownModule {}
