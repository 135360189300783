import { Directive, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaModuleConfig, MetaOptions, META_MODULE_CONFIG } from './meta.config';

@Directive({
  selector: '[xMeta]',
})
export class MetaDirective implements OnDestroy, OnInit {
  @Input()
  set meta(options: MetaOptions | null | undefined) {
    if (options) {
      this.metaTitle = options.metaTitle;
      this.metaDescription = options.metaDescription;
      this.metaKeywords = options.metaKeywords;
      this.metaImage = options.metaImage;
    } else {
      this.removeAll();
    }
  }

  @Input()
  set metaTitle(title: string | undefined | null) {
    if (title) {
      this.titleService.setTitle(
        `${title} ${this.config.titleDivider ?? '|'} ${this.config.titleSuffix}`,
      );
    } else {
      this.titleService.setTitle(`${this.config.titleSuffix}`);
    }
  }

  @Input()
  set metaDescription(description: string | undefined | null) {
    this.updateTag('description', description);
  }

  @Input()
  set metaKeywords(keywords: string | undefined | null) {
    this.updateTag('keywords', keywords);
  }

  @Input()
  set metaImage(url: string | { url?: string | null } | undefined | null) {
    let incomingImageUrl = typeof url === 'object' && url !== null ? url.url : url;

    if (!incomingImageUrl) return;

    this.updateTag('og:image', incomingImageUrl);
  }

  constructor(
    private titleService: Title,
    private metaService: Meta,
    @Inject(META_MODULE_CONFIG)
    private config: MetaModuleConfig,
  ) {
    if (this.config.defaultMeta) {
      this.meta = this.config.defaultMeta;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.removeAll();
  }

  public updateTag(name: string, content: string | null | undefined) {
    if (!content) {
      this.removeTag(name);
    } else {
      this.metaService.updateTag(
        {
          name,
          content,
        },
        `name='${name}'`,
      );
    }
  }

  public removeTag(name: string) {
    this.metaService.removeTag(`name='${name}'`);
  }

  public removeAll() {
    this.metaTitle = null;
    this.metaDescription = null;
    this.metaKeywords = null;
    this.metaTitle = null;
  }
}
