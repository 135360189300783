import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IMemberRowObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-member-pill',
  templateUrl: 'member-pill.component.html',
  styleUrls: ['member-pill.component.scss'],
  host: {
    class: 'x-member-pill x-pill-base',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberPillComponent {
  private _member: Partial<IMemberRowObject>;

  @Input() set member(member: Partial<IMemberRowObject>) {
    this._member = member;
  }

  get member(): Partial<IMemberRowObject> {
    return this._member;
  }

  @HostBinding('class')
  get stateClass() {
    return this._member?.state
      ? `state-${this._member.state}-${this._member.intervalState}`
      : 'state-default';
  }

  getState(): string {
    return `${this._member.state}-${this._member.intervalState}`;
  }
}
