import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MetaModuleConfig, META_MODULE_CONFIG } from './meta.config';
import { MetaDirective } from './meta.directive';
import { SchemaMarkupService } from './schema-markup.service';

@NgModule({
  imports: [CommonModule],
  declarations: [MetaDirective],
  exports: [MetaDirective],
})
export class MetaModule {
  static forRoot(config: MetaModuleConfig): ModuleWithProviders<MetaModule> {
    return {
      ngModule: MetaModule,
      providers: [
        {
          provide: META_MODULE_CONFIG,
          useValue: config,
        },
        SchemaMarkupService,
      ],
    };
  }
}
