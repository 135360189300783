import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { MoneyFormatConfig, MONEY_FORMAT_CONFIG } from './money.config';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD',
    @Inject(MONEY_FORMAT_CONFIG) private config: MoneyFormatConfig,
  ) {}
  transform(
    cents: number | string | null | undefined,
    currencyCode: string | undefined | null,
    digitsInfo?: string,
  ) {
    if (cents === undefined || cents === null) {
      return null;
    }
    if (typeof cents === 'string') {
      cents = parseInt(cents);
    }
    if (!currencyCode) {
      currencyCode = this._defaultCurrencyCode;
    }

    const symbol = getCurrencySymbol(currencyCode, 'narrow', this._locale);
    return formatCurrency(
      cents / 100,
      this._locale,
      symbol,
      currencyCode,
      digitsInfo ?? this.config.digitsInfo,
    );
  }
}
