import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'x-taxon-pill',
  templateUrl: './taxon-pill.component.html',
  styleUrls: ['./taxon-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxonPillComponent {
  mainTaxon: string = '';
  taxonPath: string = '';
  @Input() set taxon(input: any) {
    if (!input) {
      this.mainTaxon = '';
      this.taxonPath = '';
      return;
    }

    this.mainTaxon = input.name;

    if (input.ascendants && input.ascendants.length) {
      let path: string = input.name;

      for (let i = input.ascendants.length - 1; i >= 0; i--) {
        const element = input.ascendants[i].name;
        path = element + ' | ' + path;
      }
      this.taxonPath = path;
    }
  }
}
