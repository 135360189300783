<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <x-address-form class="mt-2" [formControl]="control"></x-address-form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>
    {{ data.cancelLabel }}
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="{ value: control.value }">
    {{ data.okLabel }}
  </button>
</div>
