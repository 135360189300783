import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IUserPaymentMethodRowObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-user-payment-method-pill',
  templateUrl: './user-payment-method-pill.component.html',
  styleUrls: ['./user-payment-method-pill.component.scss'],
  host: {
    class: 'x-user-payment-method-pill x-pill-base',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPaymentMethodPillComponent {
  @Input()
  userPaymentMethod?: IUserPaymentMethodRowObject;

  @HostBinding('class')
  get stateClass() {
    return [
      this.userPaymentMethod?.state ? `state-${this.userPaymentMethod?.state}` : 'state-default',
      this.userPaymentMethod?.expired ? `expired` : 'not-expired',
      this.userPaymentMethod?.archivedAt ? `archived` : 'not-archived',
    ].join(' ');
  }
}
