import { InjectionToken } from '@angular/core';

export interface MetaOptions {
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaKeywords?: string | null;
  metaImage?: string | { url?: null | string } | null;
}

export const META_MODULE_CONFIG = new InjectionToken('META_MODULE_CONFIG');

export interface MetaModuleConfig {
  titleSuffix: string;
  titleDivider?: string;
  schemaBaseUrl?: string;
  schemaObject?: any;
  defaultMeta?: MetaOptions;
}
