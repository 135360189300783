<div class="pill-icon">
  <fa-icon [icon]="'money-bill'"></fa-icon>
</div>
<div class="pill-body" *ngIf="payment" [matTooltip]="payment.errorDescription ?? ''">
  <div class="pill-label">
    <span>{{ 'PaymentState.' + payment.state | l }}</span>
    &nbsp;
    <span *ngIf="payment.amount">{{ payment.amount | money : payment.currency }}</span>
    <div *ngIf="payment.errorDescription" class="error" [matTooltip]="payment.errorDescription">
      {{ payment.errorDescription }}
    </div>
  </div>
</div>
