import { Component, HostBinding, Input } from '@angular/core';
import { IOrderTagItem } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-order-tag-pill',
  templateUrl: 'order-tag-pill.component.html',
  styleUrls: ['order-tag-pill.component.scss'],
  host: {
    class: 'x-order-tag-pill',
  },
})
export class OrderTagPillComponent {
  @Input()
  tag: Partial<IOrderTagItem>;

  @HostBinding('class')
  get colorClass() {
    return this.tag.color ? `color-${this.tag.color}` : 'color-default';
  }
}
