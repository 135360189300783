<ng-container [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Length</mat-label>
    <input
      xOnlyPositiveNumber
      matInput
      type="number"
      formControlName="length"
      autocomplete="disabled"
      min="0"
    />
    <span matSuffix>mm</span>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Width</mat-label>
    <input
      xOnlyPositiveNumber
      matInput
      type="number"
      formControlName="width"
      autocomplete="disabled"
      min="0"
    />
    <span matSuffix>mm</span>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Height</mat-label>
    <input
      xOnlyPositiveNumber
      matInput
      type="number"
      formControlName="height"
      autocomplete="disabled"
      min="0"
    />
    <span matSuffix>mm</span>
  </mat-form-field>
</ng-container>
