import { Component, Input } from '@angular/core';
import { IBulkOrderRowObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-bulk-order-pill',
  templateUrl: 'bulk-order-pill.component.html',
  styleUrls: ['bulk-order-pill.component.scss'],
  host: {
    class: 'x-bulk-order-pill x-pill-base',
  },
})
export class BulkOrderPillComponent {
  @Input()
  bulkOrder: Partial<IBulkOrderRowObject>;
}
