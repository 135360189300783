<ng-container *ngIf="shipment as s">
  <div class="pill-icon">
    <fa-icon icon="truck"></fa-icon>
  </div>
  <div class="pill-body">
    <div class="pill-label">
      {{ 'ShipmentState.' + s.state | l }}
    </div>
    <div>
      {{ s.method?.name }}
    </div>
  </div>
  <div class="pill-body" *ngIf="s.slot as slot">
    <div class="pill-label">
      {{ slot.startAt | datetime: 'mediumDate' : s.method?.timezone }}
    </div>
    <div class="pill-line">
      {{ slot.startAt | datetime: 'shortTime' : s.method?.timezone }}
    </div>
  </div>
</ng-container>
