<mat-form-field>
  <mat-label>
    <fa-icon icon="id-card" matPrefix></fa-icon>
    {{ verb }} Membership
  </mat-label>

  <x-model-autocomplete-chips
    *ngIf="multiple"
    [formControl]="control"
    [dataSource]="autoDataSource"
    [displayWith]="autoDataSource.displayFn"
    [transformer]="transformer"
    placeholder="Search memberships..."
  ></x-model-autocomplete-chips>
  <x-model-autocomplete
    *ngIf="!multiple"
    [formControl]="control"
    [dataSource]="autoDataSource"
    [displayWith]="autoDataSource.displayFn"
    [transformer]="transformer"
    placeholder="Search membership..."
  ></x-model-autocomplete>
</mat-form-field>
