import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MarkdownParser } from '../mardown-parser';

@Component({
  selector: 'x-markdown-view',
  template: '',
  styleUrls: ['./markdown-view.component.scss'],
  host: {
    class: 'x-markdown-view',
  },
})
export class MarkdownViewComponent implements OnInit {
  // https://marked.js.org/using_advanced
  options = {
    breaks: true,
  };

  @Input()
  set source(source: string | null | undefined) {
    if (this._source !== source) {
      this._source = source;
      this.parsed = this._source
        ? this.domSanitizer.bypassSecurityTrustHtml(this.parser.parse(this._source, this.options))
        : null;
    }
  }
  get source() {
    return this._source;
  }

  @HostBinding('innerHtml')
  parsed: string | SafeHtml | null;

  _source: string | null | undefined;

  constructor(private parser: MarkdownParser, private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
