<h2 mat-dialog-title>{{ verb }} Subscription</h2>
<form
  mat-dialog-content
  [formGroup]="subscriptionForm"
  id="subscription_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>
          <fa-icon icon="signature"></fa-icon>
          Name
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="name"
          required
          autocomplete="disabled"
          maxlength="60"
        />
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>

  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="dialog.close()">Cancel</button>
    <button mat-button type="submit" color="primary" form="subscription_form" (click)="submit()">
      {{ verb }}
    </button>
  </div>
</form>
