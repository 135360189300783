import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductVariantService } from '@x/ecommerce/domain-client';
import { SubscriptionVariantProductQuantityInput } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface SubscriptionVariantProductQuantityDialogData {
  subscriptionVariantId: number;
  productVariantId?: number | null;
  quantity?: number | null;
  id?: number | null;
}

export interface SubscriptionVariantProductQuantityDialogResult {
  data: SubscriptionVariantProductQuantityInput;
  productName: String;
}

@Component({
  selector: 'x-subscription-variant-product-quantity-create-dialog',
  templateUrl: './subscription-variant-product-quantity-dialog.component.html',
  styleUrls: ['./subscription-variant-product-quantity-dialog.component.scss'],
  // providers: [ProductVariantAutocompleteDatasource],
})
export class SubscriptionVariantProductQuantityDialogComponent {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  subscriptionVariantProductQuantityId: number;
  verb: string = 'Create';
  update: boolean = false;

  subscriptionVariantProductQuantityForm = new UntypedFormGroup({
    quantity: new UntypedFormControl(this.data.quantity || 1, Validators.required),
    productVariant: new UntypedFormControl(this.data.productVariantId, Validators.required),
  });

  constructor(
    public dialog: MatDialogRef<SubscriptionVariantProductQuantityDialogComponent>,
    private readonly productVariantService: ProductVariantService,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionVariantProductQuantityDialogData,
  ) {
    if (data.productVariantId) {
      this.verb = 'Update';
      this.update = true;
    }
  }

  submit() {
    this.subscriptionVariantProductQuantityForm.updateValueAndValidity();

    if (this.subscriptionVariantProductQuantityForm.invalid) return;

    const form = this.subscriptionVariantProductQuantityForm.value;

    const data: SubscriptionVariantProductQuantityInput = {
      productVariantId: form.productVariant,
      quantity: form.quantity,
      subscriptionVariantId: this.data.subscriptionVariantId,
      id: this.data.id,
    };

    this.productVariantService
      .fetchById(form.productVariant)
      .pipe(
        takeUntil(this._destroy$),
        tap(
          (product) => this.dialog.close({ data, productName: product.name }),
          (error) => {
            console.error('Subscription Variant Product Quantity submit error', error);
          },
        ),
      )
      .subscribe();
  }
}
