<h2 mat-dialog-title>{{ verb }} Subscriber</h2>
<form mat-dialog-content id="subscriber_form_dialog" (ngSubmit)="submit()" [formGroup]="formGroup">
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>User</mat-label>
        <x-data-autocomplete
          formControlName="userId"
          [provider]="Providers.UserItemCollectionProvider"
        ></x-data-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Subscription Variant</mat-label>
        <x-data-select
          formControlName="subscriptionVariantId"
          [provider]="Providers.SubscriptionVariantItemCollectionProvider"
        ></x-data-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Locale</mat-label>
        <x-data-select
          formControlName="locale"
          [provider]="Providers.ChannelLocaleItemCollectionProvider"
        ></x-data-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>State</mat-label>
        <x-enum-select
          formControlName="state"
          enum="SubscriberState"
          nullLabel="Unchanged"
        ></x-enum-select>
      </mat-form-field>
      <mat-form-field *ngIf="formGroup.getRawValue().userId as userId">
        <mat-label>Payment Method</mat-label>
        <x-data-select
          formControlName="userPaymentMethodId"
          [provider]="Providers.UserPaymentMethodItemCollectionProvider"
          [filter]="{ userIds: [userId], expired: false }"
        ></x-data-select>
      </mat-form-field>
      <mat-form-field *ngIf="formGroup.getRawValue().userId as userId">
        <mat-label>Shipping Address</mat-label>
        <x-data-autocomplete
          formControlName="shippingAddressId"
          [provider]="Providers.AddressItemCollectionProvider"
          [filter]="{ userIds: [userId] }"
        >
          <x-address-pill
            *xDataAutocompleteOptionDef="let option"
            [address]="option.item"
          ></x-address-pill>
        </x-data-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Interval</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="startAt" />
          <input matEndDate placeholder="End date" formControlName="endAt" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-button (click)="clear()">Clear</button>
            <button mat-flat-button color="primary" matDateRangePickerApply>Ok</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" type="submit" form="subscriber_form_dialog">
    {{ verb }}
  </button>
</div>
