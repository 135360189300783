import { InjectionToken } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const MENU_ITEMS = new InjectionToken<MenuItem[][]>('MENU_ITEMS');

export interface MenuItem {
  id: string;
  title: string;
  icon?: IconProp;
  route?: string;
  parentId?: string;
  position?: number;
  permissions?: string[];
}

export interface MenuItemNode extends MenuItem {
  children: Array<MenuItemNode>;
}
