import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IOrderDetailObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-order-payment-pill',
  templateUrl: 'order-payment-pill.component.html',
  styleUrls: ['order-payment-pill.component.scss'],
  host: {
    class: 'x-order-payment-pill x-pill-base',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPaymentPillComponent {
  @Input()
  order: Partial<IOrderDetailObject>;

  @HostBinding('class')
  get stateClass() {
    return this.order.state ? `state-${this.order.paymentState}` : 'state-default';
  }
}
