<mat-form-field>
  <mat-label>
    <fa-icon icon="location-arrow"></fa-icon>
    {{ label }}
  </mat-label>
  <mat-select [formControl]="control" [multiple]="multiple">
    <mat-option *ngFor="let locale of locales$ | async" [value]="locale.id">
      {{ locale.id }}
    </mat-option>
  </mat-select>
</mat-form-field>
