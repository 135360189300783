import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { WindowRef } from '@x/common/browser';
import { DataTableView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { ISubscriberRowObject, SubscriberService } from '@x/ecommerce/domain-client';
import { AddressAssignment, SubscriberFilterInput } from '@x/schemas/ecommerce';
import { SubscriptionDialogService } from '../../../subscriptions/services/subscription-dialog.service';

@Component({
  selector: 'x-subscriber-table',
  templateUrl: './subscriber-table.component.html',
  styleUrls: ['./subscriber-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-subscriber-table',
  },
})
export class SubscriberTableComponent implements OnInit {
  @Input()
  view: DataTableView<ISubscriberRowObject, SubscriberFilterInput, any, number>;

  constructor(
    private subscriberService: SubscriberService,
    private subscriptionDialogService: SubscriptionDialogService,
    private route: ActivatedRoute,
    private promptDialogs: PromptDialogService,
    private snackbar: MatSnackBar,
    private window: WindowRef,
  ) {}

  ngOnInit(): void {}

  createSubscriber(subscriptionVariantId: number) {
    this.subscriptionDialogService
      .openSubscriberCreateDialog({ subscriptionVariantId })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;

        this.view.mutate(() => this.subscriberService.create(result)).subscribe();
      });
  }

  async editSubscriber(subscriber: ISubscriberRowObject) {
    const result = await this.subscriptionDialogService
      .openSubscriberUpdateDialog({
        id: subscriber.id,
      })
      .afterClosed()
      .toPromise();

    if (!result) return;

    this.view
      .mutateRow(subscriber.id, () =>
        this.subscriberService.update({ id: subscriber.id, ...result }),
      )
      .subscribe();
  }

  async editSubscriberAddress(subscriber: ISubscriberRowObject) {
    const result = await this.subscriptionDialogService
      .openSubscriberAddressUpdateDialog(subscriber.shippingAddress)
      .afterClosed()
      .toPromise();

    if (!result) return;

    this.view
      .mutateRow(subscriber.id, (subscriberId) =>
        this.subscriberService.updateAddress({
          subscriberId,
          assignment: AddressAssignment.Shipping,
          address: result.value,
        }),
      )
      .subscribe();
  }

  async activateSubscriber(subscriber: ISubscriberRowObject) {
    const result = await this.promptDialogs
      .confirm({
        title: 'Activate Subscriber',
        message: 'Are you sure you want to activate this subscriber?',
      })
      .toPromise();

    if (!result) return;

    this.view.mutateRow(subscriber.id, (id) => this.subscriberService.activate(id)).subscribe();
  }

  async pauseSubscriber(subscriber: ISubscriberRowObject) {
    const result = await this.promptDialogs
      .confirm({
        title: 'Pause Subscriber',
        message: 'Are you sure you want to pause this subscriber?',
      })
      .toPromise();

    if (!result) return;

    this.view.mutateRow(subscriber.id, (id) => this.subscriberService.pause(id)).subscribe();
  }

  async activateSelectedSubscribers() {
    const result = await this.promptDialogs
      .confirm({
        title: 'Activate Subscribers',
        message: 'Are you sure you want to activate the selected subscribers?',
      })
      .toPromise();

    if (!result) return;

    this.view.mutateEachSelected((id) => this.subscriberService.activate(id)).subscribe();
  }

  async pauseSelectedSubscribers() {
    const result = await this.promptDialogs
      .confirm({
        title: 'Pause Subscribers',
        message: 'Are you sure you want to pause the selected subscribers?',
      })
      .toPromise();

    if (!result) return;

    this.view.mutateEachSelected((id) => this.subscriberService.pause(id)).subscribe();
  }

  async printSubscriberRecords() {
    // this.view.mutateEachSelected((ids) => {
    //   this.subscriberService.downloadRecords(ids).subscribe();
    // });

    this.view
      .mutateSelected((ids) =>
        this.subscriberService.downloadRecords({ ...this.view.getQuery().filter, ids }),
      )
      .subscribe((state) => {
        if (state && state.isSuccessState()) {
          const url = state.data?.url;

          if (url)
            this.snackbar
              .open(`Subscriber Records are ready`, 'Download', { duration: 10000 })
              .onAction()
              .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  async updateTagsBulk(add = true) {
    const res = await this.subscriptionDialogService
      .openSubscriberTagDialog(add)
      .afterClosed()
      .toPromise();

    if (res && res.assign) {
      await this.view
        .mutateEachSelected((subscriberId) =>
          add
            ? this.subscriberService.addTags({ subscriberId, tagIds: res.value })
            : this.subscriberService.removeTags({ subscriberId, tagIds: res.value }),
        )
        .toPromise();
    }
  }

  async updateTags(subscriber: ISubscriberRowObject, add = true) {
    const res = await this.subscriptionDialogService
      .openSubscriberTagDialog(add)
      .afterClosed()
      .toPromise();

    if (res && res.assign) {
      await this.view
        .mutateRow(subscriber.id, (subscriberId) =>
          add
            ? this.subscriberService.addTags({ subscriberId, tagIds: res.value })
            : this.subscriberService.removeTags({ subscriberId, tagIds: res.value }),
        )
        .toPromise();
    }
  }
}
