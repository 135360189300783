import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { CountryService } from '@x/geocode/client';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'x-country-select',
  template: `
    <mat-select
      [multiple]="multiple"
      [placeholder]="placeholder"
      [value]="value"
      (selectionChange)="onChange($event.value)"
    >
      <mat-select-trigger>
        <div class="option" *ngIf="select.value as value">
          <x-country-flag [code]="value"></x-country-flag>
          &nbsp;{{ select.triggerValue }}
        </div>
      </mat-select-trigger>
      <ng-container *ngIf="countries$ | async as countries">
        <mat-option
          class="option"
          *ngFor="let country of countries; trackBy: trackByCode"
          [value]="country.code"
        >
          <x-country-flag [code]="country.code"></x-country-flag>
          &nbsp;{{ country.name }}
        </mat-option>
      </ng-container>
    </mat-select>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class CountrySelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  placeholder = 'Country';

  @Input()
  multiple = false;

  @ViewChild(MatSelect, { static: true })
  select: MatSelect;

  value: string | null | undefined;

  countries$ = this.countryService.fetchCountrySearch().pipe(
    catchError((e) => {
      if (this.ngControl?.control) {
        this.ngControl.control.setErrors({ load_error: true });
      }

      return of([]);
    }),
  );

  onChange: (v: any) => {};
  onTouch: () => {};

  constructor(
    private readonly countryService: CountryService,
    @Optional() private ngControl?: NgControl,
    @Optional() private matField?: MatFormField,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.matField) {
      this.matField._control = this.select;
    }
  }

  trackByCode: TrackByFunction<any> = (i, c) => c.code;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
