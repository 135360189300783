import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import {
  MembershipAutocompleteDatasource,
  MembershipIdTransformer,
} from '@x/ecommerce/domain-data';

@Component({
  selector: 'x-member-membership-filter-field',
  templateUrl: './member-membership-filter-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MembershipAutocompleteDatasource, MembershipIdTransformer],
  preserveWhitespaces: false,
  styles: ['mat-form-field { width: 100%; }'],
})
export class MemberMembershipFilterFieldComponent implements ControlValueAccessor {
  private _onTouched: any = () => {};

  control = new UntypedFormControl();

  @Input()
  verb?: string = 'Filter';

  @Input()
  multiple?: boolean = true;

  constructor(
    public autoDataSource: MembershipAutocompleteDatasource,
    public transformer: MembershipIdTransformer,
    @Optional()
    @Self()
    public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe((value) => {
      if (value && (value.length > 0 || typeof value === 'number')) {
        fn(value);
      } else fn(null);
    });
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(obj: any): void {
    if (!obj) return;
    this.control.patchValue(obj);
  }
}
