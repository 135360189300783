import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountryFlagComponent } from './country-flag.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CountryFlagComponent],
  exports: [CountryFlagComponent],
})
export class CountryFlagModule {}
