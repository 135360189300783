import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IPaymentDetailObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-payment-pill',
  templateUrl: 'payment-pill.component.html',
  styleUrls: ['payment-pill.component.scss'],
  host: {
    class: 'x-payment-pill x-pill-base',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentPillComponent {
  @Input()
  payment?: Partial<IPaymentDetailObject>;

  @HostBinding('class')
  get stateClass() {
    return this.payment?.state ? `state-${this.payment.state}` : 'state-default';
  }
}
