import { LanguageDeclaration } from '@x/common/locale';

export interface USER_ADDRESS_INDEX_COLUMN_KEYS {
  id: string;
  street: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  defaultBillingAddress: string;
  defaultShippingAddress: string;
}

const COLUMNS: USER_ADDRESS_INDEX_COLUMN_KEYS = {
  id: 'Id',
  street: 'Street',
  city: 'City',
  province: 'Province',
  country: 'Country',
  postalCode: 'Postal Code',
  defaultBillingAddress: 'Default billing',
  defaultShippingAddress: 'Default shipping',
};

export const USER_ADDRESS_INDEX_LANGUAGE: LanguageDeclaration = {
  userAddressIndex: {
    title: 'User Addresses',
    subtitle: 'Index',
    column: { ...COLUMNS },
    action: {
      edit: 'Edit ',
      archive: 'Remove ',
      add: 'Add ',
      defaultBillingAddress: 'Default billing ',
      defaultShippingAddress: 'Default shipping ',
    },
  },
};
