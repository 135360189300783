<h2 mat-dialog-title>{{ verb }} Subscription Variant</h2>
<form
  mat-dialog-content
  [formGroup]="subscriptionVariantForm"
  id="subscription_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>
          <fa-icon icon="signature"></fa-icon>
          Name
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="name"
          required
          autocomplete="disabled"
          maxlength="60"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Run Interval</mat-label>
        <x-enum-select formControlName="interval" enum="SubscriptionRunInterval"></x-enum-select>
      </mat-form-field>

      <!--Channels-->
      <x-channel-select
        icon="globe-africa"
        label="Select Channel"
        formControlName="channel"
        [multiple]="false"
      ></x-channel-select>

      <!--Locale-->
      <x-locale-select
        label="Select Locale"
        formControlName="locale"
        [multiple]="false"
      ></x-locale-select>

      <mat-form-field appearance="fill">
        <mat-label>
          <fa-icon icon="clock"></fa-icon>
          Duration
        </mat-label>
        <x-duration-input-control
          formControlName="duration"
          [format]="['mth', 'wk', 'd', 'hr']"
        ></x-duration-input-control>
        <mat-hint>Duration, empty indicates no-expiry</mat-hint>
      </mat-form-field>

      <mat-slide-toggle formControlName="autoRenew" class="mt-4">Auto Renew</mat-slide-toggle>
    </x-form-panel>
  </x-dashboard-form-layout>

  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="dialog.close()">Cancel</button>
    <button mat-button type="submit" color="primary" form="subscription_form">
      {{ verb }}
    </button>
  </div>
</form>
