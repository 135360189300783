import { LanguageDeclaration } from '@x/common/locale';

export const ADDRESS_LANGUAGE: LanguageDeclaration = {
  address: {
    prompt: {
      untouched: {
        title: 'Nothing to update',
        message: '',
        actions: {
          okay: 'Okay',
        },
      },
      remove: {
        title: 'Remove address',
        message: 'Are you sure you want to remove this address?',
        yes: 'Yes',
        no: 'No',
      },
    },
  },
};
