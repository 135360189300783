import { Component, HostBinding, Input } from '@angular/core';
import { ISubscriberItemObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-subscriber-pill',
  templateUrl: 'subscriber-pill.component.html',
  styleUrls: ['subscriber-pill.component.scss'],
  host: {
    class: 'x-subscriber-pill x-pill-base',
  },
})
export class SubscriberPillComponent {
  @Input()
  subscriber: Partial<ISubscriberItemObject>;

  @HostBinding('class')
  get stateClass() {
    if (this.subscriber.intervalState === 'PENDING') return 'state-PENDING';
    if (this.subscriber.intervalState === 'EXPIRED') return 'state-EXPIRED';
    return this.subscriber.state ? `state-${this.subscriber.state}` : 'state-default';
  }

  get state() {
    if (this.subscriber.intervalState === 'PENDING') return 'SubscriberIntervalState.PENDING';
    if (this.subscriber.intervalState === 'EXPIRED') return 'SubscriberIntervalState.EXPIRED';
    return `SubscriberState.${this.subscriber.state}`;
  }
}
