<div class="pill-icon">
  <fa-icon [icon]="'money-bill'"></fa-icon>
</div>
<div class="pill-body">
  <div class="pill-label">
    <span>{{ 'OrderPaymentState.' + order.paymentState | l }}</span>
    &nbsp;
    <span *ngIf="order.paidTotal">{{ order.paidTotal | money : order.currency }}</span>
  </div>
  <div>
    <ng-container *ngFor="let payment of order.payments">
      <span *ngIf="payment.state === 'COMPLETED' || payment.state === 'REFUNDED'">
        {{ payment.method.name }}
      </span>
    </ng-container>
  </div>
</div>
