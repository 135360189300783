<h2 mat-dialog-title>{{ verb }} Run</h2>
<form
  mat-dialog-content
  [formGroup]="subscriptionRunForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill">
        <mat-label>Run Interval</mat-label>
        <mat-date-range-input
          [rangePicker]="picker"
          [disabled]="verb === 'Generate' && generateRunDates"
        >
          <input matStartDate placeholder="Start date" formControlName="runStart" />
          <input matEndDate placeholder="End date" formControlName="runEnd" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <mat-slide-toggle
        *ngIf="verb === 'Generate'"
        [(ngModel)]="generateRunDates"
        [ngModelOptions]="{ standalone: true }"
      >
        Generate Run Dates
      </mat-slide-toggle>
    </x-form-panel>
  </x-dashboard-form-layout>

  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="dialog.close()">Cancel</button>
    <button mat-button type="submit" color="primary" form="subscription_form" (click)="submit()">
      {{ verb }}
    </button>
  </div>
</form>
