import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MembershipService } from '@x/ecommerce/domain-client';
import { SubscriptionMembershipBenefitInput } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface SubscriptionMembershipBenefitDialogData {
  subscriptionVariantId: number;
}

export interface SubscriptionMembershipBenefitDialogResult {
  data: SubscriptionMembershipBenefitInput;
  membershipName: String;
}

@Component({
  selector: 'x-subscription-membership-benefit-create-dialog',
  templateUrl: './subscription-membership-benefit-dialog.component.html',
  styleUrls: ['./subscription-membership-benefit-dialog.component.scss'],
  // providers: [ProductVariantAutocompleteDatasource],
})
export class SubscriptionMembershipBenefitDialogComponent {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  subscriptionMembershipBenefitId: number;
  verb: string = 'Create';

  subscriptionMembershipBenefitForm = new UntypedFormGroup({
    duration: new UntypedFormControl(null),
    membershipId: new UntypedFormControl([], Validators.required),
  });

  constructor(
    public dialog: MatDialogRef<SubscriptionMembershipBenefitDialogComponent>,
    private readonly membershipService: MembershipService,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionMembershipBenefitDialogData,
  ) {}

  submit() {
    this.subscriptionMembershipBenefitForm.updateValueAndValidity();

    if (this.subscriptionMembershipBenefitForm.invalid) return;

    const form = this.subscriptionMembershipBenefitForm.value;

    const data: SubscriptionMembershipBenefitInput = {
      membershipId: form.membershipId,
      variantId: this.data.subscriptionVariantId,
      duration: form.duration,
    };

    this.membershipService
      .fetchItem(form.membershipId)
      .pipe(
        takeUntil(this._destroy$),
        tap(
          (membership) => this.dialog.close({ data, membershipName: membership.name }),
          (error) => {
            console.error('Subscription Membership Benefit submit error', error);
          },
        ),
      )
      .subscribe();
  }
}
