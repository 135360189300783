import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionRunService } from '@x/ecommerce/domain-client';
import { Subject, tap } from 'rxjs';

export interface SubscriptionRunDialogData {
  subscriptionRunId?: number;
  subscriptionVariantId?: number;
  okLabel?: string;
}

export interface SubscriptionRunDialogResult {
  data?: { subscriptionRunId: number };
}

@Component({
  selector: 'x-subscription-run-dialog',
  templateUrl: './subscription-run-dialog.component.html',
  styleUrls: ['./subscription-run-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionRunDialogComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject<void>();

  subscriptionRunId: number;
  verb: string;

  @Input()
  generateRunDates = true;

  subscriptionRunForm = new FormGroup({
    runStart: new FormControl(''),
    runEnd: new FormControl(''),
  });

  constructor(
    private subscriptionRunService: SubscriptionRunService,
    public dialog: MatDialogRef<SubscriptionRunDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionRunDialogData,
  ) {
    this.verb = data?.okLabel || 'Edit';
  }

  ngOnInit(): void {
    if (this.data?.subscriptionRunId) {
      this.subscriptionRunService
        .fetchRow(this.data?.subscriptionRunId)
        .pipe(
          tap((option) => {
            this.subscriptionRunForm.patchValue({
              runStart: option.runStart,
              runEnd: option.runEnd,
            });
          }),
        )
        .subscribe();
    }
  }

  submit() {
    this.subscriptionRunForm.updateValueAndValidity();

    if (this.subscriptionRunForm.invalid) return;

    if (this.verb === 'Edit' && this.data?.subscriptionRunId) {
      this.subscriptionRunService
        .updateRun(this.data.subscriptionRunId, this.subscriptionRunForm.value)
        .subscribe((res) => {
          this.dialog.close({ data: { subscriptionRunId: res.id } });
        });

      return;
    }

    if (this.verb === 'Generate' && this.data.subscriptionVariantId) {
      this.subscriptionRunService
        .createRun(this.data?.subscriptionVariantId, {
          runStart: this.subscriptionRunForm.value.runStart,
          runEnd: this.subscriptionRunForm.value.runEnd,
        })
        .subscribe((res) => {
          this.dialog.close({ data: { subscriptionRunId: res.id } });
        });
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
