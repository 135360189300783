import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowRef } from '@x/common/browser';
import { isAbsolute, normalizeRelative } from '@x/common/utils';
import { MetaModuleConfig, META_MODULE_CONFIG } from './meta.config';

/**
 * https://schema.org/docs/full.html
 */
@Injectable({ providedIn: 'root' })
export class SchemaMarkupService {
  scriptId: string = 'schema-markup';
  scriptType: string = 'application/ld+json';

  isBrowser: boolean = true;

  baseSchema: any = {};
  schemaGraph: Array<any> = [];

  get schemaText() {
    return JSON.stringify(
      Object.assign({}, this.baseSchema, {
        '@graph': this.schemaGraph,
      }),
    );
  }

  get scriptElement() {
    let el = this.windowRef.document.getElementById(this.scriptId);
    if (!el) {
      el = this.windowRef.document.createElement('script');
      el.setAttribute('id', this.scriptId);
      el.setAttribute('type', this.scriptType);
      this.windowRef.document.head.appendChild(el);
    }
    return el;
  }

  constructor(
    private windowRef: WindowRef,
    @Inject(META_MODULE_CONFIG) private config: MetaModuleConfig,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (config.schemaObject) this.baseSchema = config.schemaObject;
  }

  add(schemaType: string, schemaJson: any) {
    if (this.isBrowser) return;
    const json = Array.isArray(schemaJson) ? schemaJson : [schemaJson];
    json.forEach((item) => {
      this.schemaGraph.push({
        '@type': schemaType,
        ...item,
      });
    });
    this.scriptElement.innerHTML = this.schemaText;
  }

  ensureAbsoluteUrl(url: string | undefined | null) {
    if (!url) return null;
    if (isAbsolute(url)) return url;
    return `${this.config.schemaBaseUrl}${normalizeRelative(url)}`;
  }
}
