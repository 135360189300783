import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { MenuItem, MenuItemNode } from './menu-item';
import { MenuService } from './menu.service';

interface MenuItemStateNode {
  item: MenuItem;
  active: boolean;
  children: MenuItemStateNode[];
  parent: MenuItemStateNode | null;
}

export interface MenuItemFlatNode {
  item: MenuItem;
  active: boolean;
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'x-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  host: {
    class: 'x-menu',
  },
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {
  private _transformer = (node: MenuItemStateNode, level: number) => {
    return <MenuItemFlatNode>{
      item: node.item,
      expandable: !!node.children && node.children.length > 0,
      level: level,
      active: node.active,
    };
  };

  trackMenuItem = (i: number, item: MenuItem) => {
    return item.id;
  };

  isPinnedExpanded = true;
  pinnedNodes: MenuItem[] = [];
  pinnedControl = new SelectionModel<string>(true);

  expandedControl = new NestedTreeControl<MenuItemNode, string>((node) => node.children, {
    trackBy: (node) => node.id,
  });

  activeControl = new NestedTreeControl<MenuItemNode, string>((node) => node.children, {
    trackBy: (node) => node.id,
  });

  nodes: MenuItemNode[] = [];

  currentUrl = '';

  private _destroy$ = new Subject<void>();

  constructor(
    public menuService: MenuService,
    private router: Router,
    private changeRef: ChangeDetectorRef,
  ) {
    this.expandedControl.expansionModel.select(...this.menuService.loadSelection());
    this.pinnedControl.select(...this.menuService.loadPinned());
  }
  ngAfterViewInit(): void {
    this.updateActiveNodes();
  }

  ngOnInit() {
    this.menuService.observeTree().subscribe((nodes) => {
      this.nodes = nodes;
      this.changeRef.markForCheck();
    });

    this.router.events.pipe(takeUntil(this._destroy$)).subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
        this.activeControl.collapseAll();

        // close menu with navigation if overlayed
        this.closeMenuIfOverlay();
      }
      if (event instanceof NavigationEnd) {
        this.updateActiveNodes();
        this.changeRef.markForCheck();
      }
      this.changeRef.markForCheck();
    });

    this.expandedControl.expansionModel.changed
      .pipe(
        debounceTime(300),
        tap((e) => this.menuService.saveSelection(this.expandedControl.expansionModel.selected)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.updatePinnedNodes();

    this.menuService.restoreState();

    this.updateActiveNodes();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  togglePinned() {
    this.isPinnedExpanded = !this.isPinnedExpanded;
  }

  togglePin(node: MenuItemNode, event?: MouseEvent) {
    event?.stopPropagation();
    event?.preventDefault();

    this.pinnedControl.toggle(node.id);
    this.updatePinnedNodes();
    this.menuService.savePinned(this.pinnedControl.selected);
  }

  hasChild = (_: number, node: MenuItemNode) => node.children.length > 0;
  trackById = (_: number, node: MenuItemNode) => node.id;

  private updateActiveNodes() {
    this.getActiveNodes(this.nodes).forEach((node) => {
      this.activeControl.expand(node);
    });
  }

  private expandActiveNodes() {
    this.getActiveNodes(this.nodes).forEach((node) => {
      if (node.children.length) {
        this.expandedControl.expand(node);
      }
    });
  }

  private getActiveNodes(nodes: MenuItemNode[]) {
    let activeNodes: MenuItemNode[] = [];

    const sortedNodes = [...nodes].sort(
      (a, b) => (a.route?.split('/').length ?? 0) - (b.route?.split('/').length ?? 0),
    );

    sortedNodes.forEach((node) => {
      let active = node.route
        ? this.router.isActive(node.route, {
            paths: 'subset',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'subset',
          })
        : false;
      let activeChildren = this.getActiveNodes(node.children);

      if (active || activeChildren.length) {
        activeNodes = [...activeChildren, node];
      }
    });

    return activeNodes;
  }

  private closeMenuIfOverlay() {
    if (this.menuService.sidenav?.mode === 'over') {
      this.menuService.close();
    }
  }

  private updatePinnedNodes() {
    this.pinnedNodes = this.pinnedControl.selected
      .map((id) => this.menuService.getItem(id))
      .filter((item): item is MenuItem => !!item);
  }
}
