import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IAddressInput } from '@x/ecommerce/domain-client';
import {
  CountryAutocompleteDatasource,
  CountryCodeTransformer,
  GeocodeAutocompleteDatasource,
  ProvinceAutocompleteDatasource,
  ProvinceCodeTransformer,
} from '@x/ecommerce/domain-data';
import { Subject } from 'rxjs';

@Component({
  selector: 'x-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: AddressInputComponent },
    GeocodeAutocompleteDatasource,
    CountryAutocompleteDatasource,
    CountryCodeTransformer,
    ProvinceAutocompleteDatasource,
    ProvinceCodeTransformer,
  ],
  host: {
    class: 'x-address-input',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressInputComponent
  implements ControlValueAccessor, MatFormFieldControl<IAddressInput>
{
  static nextId = 0;

  @HostBinding()
  id = `x-address-input-${AddressInputComponent.nextId++}`;
  controlType = 'x-address-input';

  @Input()
  set value(value: IAddressInput | null) {
    this.writeValue(value);
  }
  get value() {
    return this._value;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  _placeholder: string;

  @Input() required: boolean;
  @Input() disabled: boolean;

  get empty() {
    return !this.value;
  }
  get errorState(): boolean {
    return false; // TODO
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty || this.isOpen;
  }

  @ViewChild(CdkConnectedOverlay)
  overlay: CdkConnectedOverlay;

  focused = false;
  stateChanges = new Subject<void>();

  isOpen = false;
  _value: IAddressInput | null;

  get formattedValue(): string | null {
    return this._value
      ? [
          this._value.street,
          this._value.complex,
          this._value.businessName,
          this._value.suburb,
          this._value.city,
          this._value.postalCode,
          this._value.province,
          this._value.country,
          // this._value.coordinates && this._value.coordinates.lat
          //   ? `(${this._value.coordinates.lat},${this._value.coordinates.lng})`
          //   : undefined,
        ]
          .filter((part) => !!part)
          .join(', ')
      : null;
  }

  showMap = false;

  private _onTouched: any = () => {};
  private _onChange: any = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private changeRef: ChangeDetectorRef,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(address: any): void {
    this._value = address;
    this.changeRef.markForCheck();
    this.stateChanges.next();
  }

  changed(address: IAddressInput) {
    this._value = address;
    this._onTouched();
    this._onChange(this._value);
  }

  open() {
    this.isOpen = true;
    this.showMap = false;
    this.changeRef.markForCheck();
    this.stateChanges.next();
  }

  close() {
    this.isOpen = false;
    this.changeRef.markForCheck();
    this.stateChanges.next();
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.open();
    }
  }

  onAttachOverlay() {}

  setDescribedByIds(ids: string[]) {}

  trackByValue = (i: number, o: any) => o.value;
}
