<div class="pill-icon">
  <fa-icon icon="dolly-flatbed"></fa-icon>
</div>
<div class="pill-body">
  <div class="pill-label">
    {{ 'FulfilmentState.' + fulfilment.state | l }}
  </div>
  <div>
    {{ fulfilment.warehouse?.name }}
  </div>
</div>
