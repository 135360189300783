import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CurrencySymbolPipe } from '@x/common/money/currency-symbol-pipe.pipe';
import { FromCentsPipe } from '@x/common/money/from-cents.pipe';
import { MoneyModuleConfig, MONEY_FORMAT_CONFIG } from './money.config';
import { MoneyPipe } from './money.pipe';

const DECLARATIONS = [CurrencySymbolPipe, FromCentsPipe, MoneyPipe];

@NgModule({
  declarations: DECLARATIONS,
  imports: [CommonModule],
  exports: DECLARATIONS,
})
export class MoneyModule {
  static forRoot(config: MoneyModuleConfig): ModuleWithProviders<MoneyModule> {
    return {
      ngModule: MoneyModule,
      providers: [{ provide: MONEY_FORMAT_CONFIG, useValue: config.format }],
    };
  }
}
