<form
  class="x-address-form"
  [formGroup]="addressGroup"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <div class="address-input-form-panel">
    <mat-form-field class="address-autocomplete-field" appearance="outline">
      <mat-label for="address_search_input">
        <fa-icon icon="search"></fa-icon>
        Search address in {{ addressGroup.get('country')?.value ?? 'ZA' }}...
      </mat-label>
      <x-address-autocomplete
        [formControl]="addressSearchControl"
        [countryCode]="addressGroup.get('country')?.value"
      >
        <x-cube-spinner *xAddressLoader></x-cube-spinner>
      </x-address-autocomplete>
    </mat-form-field>
    <div class="address-input-header">Address</div>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Country *</mat-label>
          <x-model-autocomplete
            [dataSource]="countryAutocompleteDataSource"
            [transformer]="countryCodeTransformer"
            [displayWith]="countryAutocompleteDataSource.displayFn"
            placeholder="Country *"
            formControlName="country"
          ></x-model-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Province</mat-label>
          <x-model-autocomplete
            [dataSource]="provinceAutocompleteDataSource"
            [transformer]="provinceCodeTransformer"
            [arguments]="addressGroup.value.country"
            [displayWith]="provinceAutocompleteDataSource.displayFn"
            placeholder="Province"
            formControlName="province"
          ></x-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <mat-form-field>
          <mat-label>Street</mat-label>
          <input
            id="address_street_input"
            matInput
            type="text"
            formControlName="street"
            placeholder="Street"
            autocomplete="street-address"
          />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Complex</mat-label>
          <input
            id="address_complex_input"
            matInput
            type="text"
            formControlName="complex"
            placeholder="Complex"
            autocomplete="complex"
          />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Business Name</mat-label>
          <input
            id="address_org_input"
            matInput
            type="text"
            formControlName="businessName"
            placeholder="Organisation"
            autocomplete="organization"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <mat-form-field>
          <mat-label>Suburb</mat-label>
          <input
            id="address_suburb_input"
            matInput
            type="text"
            formControlName="suburb"
            placeholder="Suburb"
          />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>City</mat-label>
          <input
            id="address_city_input"
            matInput
            type="text"
            formControlName="city"
            placeholder="City"
            autocomplete="address-level2"
          />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Post Code</mat-label>
          <input
            id="address_postcode_input"
            matInput
            type="text"
            formControlName="postalCode"
            placeholder="Post Code"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Alias / Nickname</mat-label>
          <input
            id="address_alias_input"
            matInput
            type="text"
            formControlName="alias"
            placeholder="Alias"
          />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Instructions</mat-label>
          <input
            id="address_instructions_input"
            matInput
            type="text"
            formControlName="instructions"
            placeholder="Instructions"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="address-input-header">Coordinates</div>
    <div class="row coordinates-row" formGroupName="coordinates">
      <div class="lat">
        <mat-form-field>
          <mat-label>Latitude</mat-label>
          <input
            id="address_coords_lat_input"
            formControlName="lat"
            matInput
            type="text"
            placeholder="Latitude"
          />
        </mat-form-field>
      </div>
      <div class="lng">
        <mat-form-field>
          <mat-label>Longitude</mat-label>
          <input
            id="address_coords_lng_input"
            matInput
            formControlName="lng"
            type="text"
            placeholder="Longitude"
          />
        </mat-form-field>
      </div>
      <div class="btn">
        <button mat-icon-button (click)="toggleShowMap()" color="primary">
          <fa-icon icon="map-marked"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="address-input-header">Recipient</div>
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input
          id="address_first_name_input"
          formControlName="firstName"
          matInput
          type="text"
          placeholder="First Name"
          autocomplete="first-name"
        />
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input
          id="address_last_name_input"
          formControlName="lastName"
          matInput
          type="text"
          placeholder="Last Name"
          autocomplete="last-name"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          id="address_email_input"
          formControlName="email"
          matInput
          type="text"
          placeholder="Email"
          autocomplete="email"
        />
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label>Phone Number</mat-label>
        <input
          id="address_phone_number_input"
          formControlName="phoneNumber"
          matInput
          type="text"
          placeholder="Phone Number"
          autocomplete="phone"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="address-input-map-panel" [hidden]="!showMap">
    <google-map
      #mapControl
      width="100%"
      height="100%"
      [options]="mapOptions"
      (mapClick)="onMapClick($event)"
    >
      <map-marker
        *ngIf="addressMarker$ | async as marker"
        [options]="{ draggable: true }"
        [position]="marker"
        (mapDragend)="markerDragEnd($event)"
      ></map-marker>
    </google-map>

    <button mat-mini-fab (click)="toggleShowMap()">
      <fa-icon icon="check"></fa-icon>
    </button>
  </div>
</form>
