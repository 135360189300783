import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { DimensionsInput } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-dimension-form-input',
  templateUrl: './dimension-form-input.component.html',
  styleUrls: ['./dimension-form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimensionFormInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _onTouched: any = () => {};
  private _onChange: any = () => {};

  @Input() set validators(validators: ValidatorFn[]) {
    for (const [key, control] of Object.entries(this.formGroup.controls)) {
      control.addValidators(validators);
      control.updateValueAndValidity();
    }
  }

  formGroup = new UntypedFormGroup({
    length: new UntypedFormControl(null),
    width: new UntypedFormControl(null),
    height: new UntypedFormControl(null),
  });

  constructor(@Optional() @Self() ngControl: NgControl) {
    if (ngControl) ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((v: DimensionsInput) => {
          const val = this.formGroup.invalid ? null : v;
          this._onChange(val);
          this._onTouched();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(obj: DimensionsInput): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }
}
