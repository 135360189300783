import { Component, Input } from '@angular/core';

@Component({
  selector: 'x-country-flag',
  templateUrl: 'country-flag.component.html',
  styleUrls: ['country-flag.component.scss'],
  preserveWhitespaces: false,
})
export class CountryFlagComponent {
  @Input('code')
  set code(code: string) {
    this._code = code;
    if (typeof code === 'string' && code.length === 2) {
      if (code === 'uk') {
        code = 'gb';
      }

      this._src = `https://flagcdn.com/24x18/${code.toLowerCase()}.png`;
    } else {
      this._src = null;
    }
  }

  _src: string | null;
  _code: string;
}
