import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IconModule } from '@x/common/icon';
import { MenuItem, MENU_ITEMS } from './menu-item';
import { MenuComponent } from './menu.component';
import { MenuService } from './menu.service';

const MENU_PROVIDERS = [MenuService];
const MENU_DECLARATIONS = [MenuComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CdkTreeModule,
    IconModule,
    MatRippleModule,
    MatTooltipModule,
  ],
  declarations: MENU_DECLARATIONS,
  exports: MENU_DECLARATIONS,
})
export class DashboardMenuModule {
  public static forRoot(menuItems: MenuItem[]): ModuleWithProviders<DashboardMenuModule> {
    return {
      ngModule: DashboardMenuModule,
      providers: [MENU_PROVIDERS, { provide: MENU_ITEMS, multi: true, useValue: menuItems }],
    };
  }

  public static forChild(menuItems: MenuItem[]): ModuleWithProviders<DashboardMenuModule> {
    return {
      ngModule: DashboardMenuModule,
      providers: [{ provide: MENU_ITEMS, multi: true, useValue: menuItems }],
    };
  }
}
