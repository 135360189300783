import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IChannelDetailObject } from '@x/ecommerce/domain-client';
import { ChannelContext } from '@x/ecommerce/domain-data';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-channel-select',
  templateUrl: './channel-select.component.html',
  styleUrls: ['./channel-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private _onChange: any;
  private _onTouched: any;
  private _destroy$ = new Subject<void>();

  @Input() multiple: boolean = false;

  @Input() label = 'Select channel';

  @Input() icon: IconProp;

  @Input()
  set validators(validators: ValidatorFn[]) {
    this.control.addValidators(validators);
    this.control.updateValueAndValidity();
  }

  control = new UntypedFormControl(null);

  channels$: Observable<IChannelDetailObject[]> = this.channelContext.getAllChannels();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private channelContext: ChannelContext,
  ) {
    ngControl.valueAccessor = this;
  }

  writeValue(channel: IChannelDetailObject): void {
    if (!channel) return;
    this.control.patchValue(channel);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((val) => {
          if (val) this._onChange(val);
          else this._onChange(null);

          this._onTouched();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
