import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogService } from '@x/dashboard/dialog';
import { IAddressInput } from '@x/ecommerce/domain-client';
import { SubscriberTagItemCollectionProvider } from '@x/ecommerce/domain-data';
import { AddressDialogService } from '../../core/services/address-dialog.service';
import {
  SubscriberFormDialogComponent,
  SubscriptionFormDialogData,
  SubscriptionFormDialogResult,
} from '../components/subscriber-form-dialog/subscriber-form-dialog.component';
import {
  SubscriptionDialogComponent,
  SubscriptionDialogData,
  SubscriptionDialogResult,
} from '../components/subscription-dialog/subscription-dialog.component';
import {
  SubscriptionMembershipBenefitDialogComponent,
  SubscriptionMembershipBenefitDialogData,
  SubscriptionMembershipBenefitDialogResult,
} from '../components/subscription-membership-benefit-dialog/subscription-membership-benefit-dialog.component';
import {
  SubscriptionRunDialogComponent,
  SubscriptionRunDialogData,
  SubscriptionRunDialogResult,
} from '../components/subscription-run-dialog/subscription-run-dialog.component';
import {
  SubscriptionVariantDialogComponent,
  SubscriptionVariantDialogData,
  SubscriptionVariantDialogResult,
} from '../components/subscription-variant-dialog/subscription-variant-dialog.component';
import {
  SubscriptionVariantProductQuantityDialogComponent,
  SubscriptionVariantProductQuantityDialogData,
  SubscriptionVariantProductQuantityDialogResult,
} from '../components/subscription-variant-product-quantity-dialog/subscription-variant-product-quantity-dialog.component';

@Injectable()
export class SubscriptionDialogService {
  constructor(
    private dialogs: MatDialog,
    private addressDialog: AddressDialogService,
    private prompts: PromptDialogService,
  ) {}

  openSubscriptionCreateDialog(subscriptionId?: number) {
    return this.dialogs.open<
      SubscriptionDialogComponent,
      SubscriptionDialogData,
      SubscriptionDialogResult
    >(SubscriptionDialogComponent, {
      data: { subscriptionId },
    });
  }

  openSubscriptionMembershipBenefitCreateDialog(subscriptionVariantId: number) {
    return this.dialogs.open<
      SubscriptionMembershipBenefitDialogComponent,
      SubscriptionMembershipBenefitDialogData,
      SubscriptionMembershipBenefitDialogResult
    >(SubscriptionMembershipBenefitDialogComponent, {
      data: { subscriptionVariantId },
    });
  }

  openSubscriptionVariantCreateDialog(subscriptionId: number) {
    return this.dialogs.open<
      SubscriptionVariantDialogComponent,
      SubscriptionVariantDialogData,
      SubscriptionVariantDialogResult
    >(SubscriptionVariantDialogComponent, {
      data: { subscriptionId },
    });
  }

  openSubscriberCreateDialog(data: SubscriptionFormDialogData) {
    return this.dialogs.open<
      SubscriberFormDialogComponent,
      SubscriptionFormDialogData,
      SubscriptionFormDialogResult
    >(SubscriberFormDialogComponent, {
      data: { ...data, label: 'Add' },
      autoFocus: false,
      maxWidth: '500px',
    });
  }

  openSubscriberUpdateDialog(data: SubscriptionFormDialogData) {
    return this.dialogs.open<
      SubscriberFormDialogComponent,
      SubscriptionFormDialogData,
      SubscriptionFormDialogResult
    >(SubscriberFormDialogComponent, {
      data: { ...data, label: 'Update' },
      autoFocus: false,
      maxWidth: '500px',
    });
  }

  openSubscriberAddressUpdateDialog(value?: IAddressInput | null) {
    return this.addressDialog.openAddressInputDialog({
      title: 'Update Subscriber Address',
      okLabel: 'Update',
      value,
    });
  }

  openSubscriptionVariantProductQuantityCreateDialog(
    subscriptionVariantId: number,
    productVariantId?: number,
    quantity?: number,
    id?: number,
  ) {
    return this.dialogs.open<
      SubscriptionVariantProductQuantityDialogComponent,
      SubscriptionVariantProductQuantityDialogData,
      SubscriptionVariantProductQuantityDialogResult
    >(SubscriptionVariantProductQuantityDialogComponent, {
      data: { subscriptionVariantId, productVariantId, quantity, id },
    });
  }

  openSubscriptionRunEditDialog(subscriptionRunId: number) {
    return this.dialogs.open<
      SubscriptionRunDialogComponent,
      SubscriptionRunDialogData,
      SubscriptionRunDialogResult
    >(SubscriptionRunDialogComponent, {
      data: {
        subscriptionRunId,
        okLabel: 'Edit',
      },
    });
  }

  openSubscriptionRunCreateDialog(subscriptionVariantId?: number) {
    return this.dialogs.open<
      SubscriptionRunDialogComponent,
      SubscriptionRunDialogData,
      SubscriptionRunDialogResult
    >(SubscriptionRunDialogComponent, {
      data: {
        okLabel: 'Generate',
        subscriptionVariantId,
      },
    });
  }

  openSubscriberTagDialog(add = true) {
    return this.prompts.select<number[]>({
      provider: SubscriberTagItemCollectionProvider,
      okLabel: add ? 'Add' : 'Remove',
      title: add ? 'Add Tags' : 'Remove Tags',
      message: add ? 'Select tags to add' : 'Select tags to remove',
      placeholder: 'Select tags...',
      multiple: true,
    });
  }
}
