<mat-form-field>
  <mat-label>
    <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
    {{ label }}
  </mat-label>
  <mat-select [formControl]="control" [multiple]="multiple">
    <mat-option *ngFor="let channel of channels$ | async" [value]="channel.id">
      {{ channel.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
