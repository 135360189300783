import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAddressItem, IGeoRegionItemObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-address-pill',
  templateUrl: 'address-pill.component.html',
  styleUrls: ['address-pill.component.scss'],
  host: {
    class: 'x-address-pill x-pill-base',
  },
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressPillComponent {
  @Input()
  address?: IAddressItem | null;

  @Input()
  region?: IGeoRegionItemObject | null;
}
