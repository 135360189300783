<x-data-table [view]="view">
  <div *xDataColumnDef="'id'; let row">{{ row.id }}</div>
  <div *xDataColumnDef="'alias'; let row">{{ row.alias }}</div>
  <div *xDataColumnDef="'street'; let row">{{ row.street }}</div>
  <div *xDataColumnDef="'city'; let row">{{ row.city }}</div>
  <div *xDataColumnDef="'province'; let row">{{ row.province }}</div>
  <div *xDataColumnDef="'country'; let row">{{ row.country }}</div>
  <div *xDataColumnDef="'postalCode'; let row">{{ row.postalCode }}</div>

  <button
    x-data-button
    *xDataAction="'editAddress'; let row"
    label="Edit Address"
    icon="edit"
    (click)="editAddress(row)"
  ></button>
  <!-- <button
    x-data-button
    *xDataAction="'archiveAddress'; let row"
    label="Archive Address"
    icon="archive"
    (click)="archiveAddress(row.id)"
  ></button> -->
</x-data-table>
