<x-data-table [view]="view" [selectable]="true">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>

  <ng-template xDataColumnDef="user" let-row>
    <x-user-pill [user]="row.user"></x-user-pill>
  </ng-template>

  <ng-template xDataColumnDef="state" let-row>
    <x-pill [color]="row.state === 'ACTIVE' ? 'primary' : 'default'">
      {{ row.state | titlecase }}
    </x-pill>
  </ng-template>

  <ng-template xDataColumnDef="locale" let-row>
    {{ row.locale }}
  </ng-template>

  <ng-template xDataColumnDef="subscriptionVariant" let-row>
    {{ row.variant.name }}
  </ng-template>

  <ng-template xDataColumnDef="shippingAddress" let-row>
    <x-address-pill [address]="row.shippingAddress"></x-address-pill>
  </ng-template>

  <ng-template xDataColumnDef="userPaymentMethod" let-row>
    {{ row.userPaymentMethod?.description }}
  </ng-template>

  <x-timestamp-column *xDataColumnDef="'timestamps'; let row" [row]="row"></x-timestamp-column>

  <ng-template xDataColumnDef="interval" let-row>
    <div [ngClass]="'interval-pill interval-' + row.intervalState">
      <span>{{ 'SubscriberIntervalState.' + row.intervalState | l }}</span>
      <div *ngIf="row.startAt">
        {{ row.startAt | datetime: 'medium' }} - {{ row.endAt | datetime: 'medium' }}
      </div>
    </div>
  </ng-template>

  <ng-container *xDataColumnDef="'tags'; let row">
    <x-subscriber-tag-pill *ngFor="let tag of row.tags" [tag]="tag"></x-subscriber-tag-pill>
  </ng-container>

  <button
    *xDataAction="'edit'; let row; primary: true"
    x-data-button
    label="Edit Subscriber"
    icon="edit"
    (click)="editSubscriber(row)"
  ></button>

  <button
    *xDataAction="'editAddress'; let row"
    x-data-button
    label="Edit Address"
    icon="edit"
    (click)="editSubscriberAddress(row)"
  ></button>

  <button
    *xDataAction="'activate'; let row"
    x-data-button
    label="Activate"
    icon="play"
    (click)="activateSubscriber(row)"
  ></button>

  <button
    *xDataAction="'pause'; let row"
    x-data-button
    label="Pause"
    icon="pause"
    (click)="pauseSubscriber(row)"
  ></button>

  <button
    *xDataAction="'tag'; let row"
    x-data-button
    label="Add Tag"
    icon="tag"
    (click)="updateTags(row, true)"
  ></button>

  <button
    *xDataAction="'tag'; let row"
    x-data-button
    label="Remove Tag"
    icon="tag"
    (click)="updateTags(row, false)"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'pause'"
    label="Pause"
    icon="pause"
    color="warn"
    (click)="pauseSelectedSubscribers()"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'activate'"
    label="Activate"
    icon="play"
    color="primary"
    (click)="activateSelectedSubscribers()"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'download'"
    label="Download"
    icon="file-arrow-down"
    color="primary"
    (click)="printSubscriberRecords()"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'tag'; let ids"
    label="Tag"
    icon="tag"
    color="primary"
    [matMenuTriggerFor]="tagMenu"
  ></button>

  <mat-menu #tagMenu>
    <button mat-menu-item (click)="updateTagsBulk(true)">Add Tag(s)</button>
    <button mat-menu-item (click)="updateTagsBulk(false)">Remove Tag(s)</button>
  </mat-menu>
</x-data-table>
