import { Inject, Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LocalStorage } from '@x/common/storage';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MenuItem, MenuItemNode, MENU_ITEMS } from './menu-item';

const SELECTED_MENU_ITEMS_STORAGE_KEY = 'selected_menu_items';
const PINNED_MENU_ITEMS_STORAGE_KEY = 'pinned_menu_items';
const MENU_STATE_KEY = 'menu_state';

export interface IMenuServiceState {
  open?: boolean;
}

@Injectable({ providedIn: 'root' })
export class MenuService {
  sidenav: MatSidenav | null = null;
  stateChanges = new Subject<void>();

  private _sortByPosition = (a: MenuItem, b: MenuItem) => {
    return (a.position || 0) - (b.position || 0);
  };

  private items: MenuItem[] = [];
  private permissions: string[] | null = null;

  constructor(
    @Inject(MENU_ITEMS)
    configuredItems: MenuItem[][],
    private localStorage: LocalStorage,
  ) {
    this.addItemsFromConfig(configuredItems);
  }

  addItem(item: MenuItem) {
    this.items = [...this.items, item];
    this.stateChanges.next();
  }

  registerSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  getItems(): MenuItem[] {
    return this.items;
  }

  observeTree(): Observable<MenuItemNode[]> {
    return this.stateChanges.pipe(
      startWith(null),
      map(() => this.buildTree()),
    );
  }

  getItem(id: string) {
    return this.items.find((item) => item.id === id);
  }

  close() {
    this.sidenav?.close();
  }

  open() {
    this.sidenav?.open();
  }

  saveState() {
    const state: IMenuServiceState = {
      open: this.sidenav?.opened ?? false,
    };

    this.localStorage.setItem(MENU_STATE_KEY, state);
  }

  restoreState() {
    const state: IMenuServiceState | null = this.localStorage.getItem(MENU_STATE_KEY);

    if (state?.open) {
      this.open();
    } else {
      this.close();
    }
  }

  saveSelection(menuIds: string[]) {
    this.localStorage.setItem(SELECTED_MENU_ITEMS_STORAGE_KEY, menuIds);
  }

  loadSelection(): string[] {
    try {
      const selection = this.localStorage.getItem(SELECTED_MENU_ITEMS_STORAGE_KEY);
      return Array.isArray(selection) ? selection : [];
    } catch (e) {
      console.warn('MenuService: Error parsing selected items from local storage', e);
      return [];
    }
  }

  savePinned(menuIds: string[]) {
    this.localStorage.setItem(PINNED_MENU_ITEMS_STORAGE_KEY, menuIds);
  }

  loadPinned(): string[] {
    try {
      const selection = this.localStorage.getItem(PINNED_MENU_ITEMS_STORAGE_KEY);
      return Array.isArray(selection) ? selection : [];
    } catch (e) {
      console.warn('MenuService: Error parsing pinned items from local storage', e);
      return [];
    }
  }

  setPermissions(permissions: string[]) {
    this.permissions = permissions;
    this.stateChanges.next();
  }

  clearPermissions() {
    this.permissions = null;
    this.stateChanges.next();
  }

  private buildTree() {
    return this.getChildNodes().filter((parents) => parents.children.length > 0);
  }

  private getChildNodes(parentId?: string): MenuItemNode[] {
    return this.items
      .filter((item) => (parentId ? item.parentId === parentId : !item.parentId))
      .filter((item: MenuItem) => {
        const permissions = this.permissions;
        if (!item.permissions || item.permissions.length === 0) return true;
        if (permissions === null) return false;
        return item.permissions.filter((p) => permissions.includes(p)).length > 0;
      })
      .map((item) => {
        return {
          ...item,
          children: this.getChildNodes(item.id),
        };
      });
  }

  private addItemsFromConfig(configuredItems: MenuItem[][]) {
    let items: MenuItem[] = [];

    configuredItems.forEach((its) => {
      items = items.concat(its);
    });

    this.items = this.items.concat(items).sort(this._sortByPosition);
  }
}
