import { Component, Input } from '@angular/core';
import { IGeoRegionItemObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-geo-region-pill',
  templateUrl: 'geo-region-pill.component.html',
  styleUrls: ['geo-region-pill.component.scss'],
  host: {
    class: 'x-geo-region-pill x-pill-base',
  },
})
export class GeoRegionPillComponent {
  @Input()
  region?: IGeoRegionItemObject | null;
}
