<div class="pill-icon">
  <x-country-flag *ngIf="address?.country as code; else defaultIcon" [code]="code"></x-country-flag>
  <ng-template #defaultIcon>
    <fa-icon icon="map-location"></fa-icon>
  </ng-template>
</div>
<div class="pill-body" *ngIf="address; else unassigned">
  <div class="pill-line">
    <span *ngIf="address.complex">
      {{ address.complex }}
    </span>
    <span *ngIf="address.businessName">
      <span *ngIf="address.complex">,&nbsp;</span>
      {{ address.businessName }}
    </span>
  </div>
  <div class="pill-line">
    <span *ngIf="address.street">{{ address.street }}</span>
    <span *ngIf="address.suburb">, {{ address.suburb }}</span>
  </div>
  <div class="pill-line">
    <span *ngIf="address.postalCode">{{ address.postalCode }}</span>
    <span *ngIf="address.city">, {{ address.city }}</span>
  </div>
  <div class="pill-line">
    <span *ngIf="address.province">{{ address.province }}</span>
    <span *ngIf="address.country">&nbsp;{{ address.country }}</span>
  </div>
  <div class="pill-line">
    <span *ngIf="address.firstName">{{ address.firstName }}</span>
    <span *ngIf="address.lastName">&nbsp;{{ address.lastName }}</span>
  </div>
  <div class="pill-line">
    <span *ngIf="address.phoneNumber">{{ address.phoneNumber }}</span>
  </div>
  <!-- <div class="pill-line">
    <span *ngIf="address.email">{{ address.email }}</span>
  </div> -->
</div>
<ng-template #unassigned>
  <div class="pill-body">Unassigned</div>
</ng-template>
