import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { IChannelDetailObject } from '@x/ecommerce/domain-client';
import {
  ProductVariantAutocompleteDatasource,
  ProductVariantIdTransformer,
} from '@x/ecommerce/domain-data';

@Component({
  selector: 'x-product-variant-autocomplete',
  templateUrl: './product-variant-autocomplete.component.html',
  styleUrls: ['./product-variant-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductVariantAutocompleteDatasource, ProductVariantIdTransformer],
})
export class ProductVariantAutocompleteComponent implements ControlValueAccessor {
  private _onTouched: any = () => {};

  @Input() label: string = 'Product Variant';

  control = new UntypedFormControl();

  constructor(
    public productVariantAutocompleteDatasource: ProductVariantAutocompleteDatasource,
    public productVariantIdTransformer: ProductVariantIdTransformer,
    @Optional()
    ngControl?: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  writeValue(channel: IChannelDetailObject): void {
    if (!channel) return;
    this.control.patchValue(channel, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
